.gmnoprint:not(.gm-style-mtc-bbw) {
  display: none;
}
.gm-style-mtc-bbw button {
  height: 25px !important;
}
.gm-style a div:has(img) {
  height: 10px !important;
}
.gm-style a div img {
  height: 10px !important;
  width: unset !important;
}
.gm-fullscreen-control {
  height: 20px !important;
  width: 20px !important;
}
.gm-fullscreen-control img {
  height: 9px !important;
  width: 9px !important;
}
